
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import JwtService from "@/core/services/JwtService";

interface IStudents {
  _id: string;
  firstName: string;
  lastName: string;
  BirthDate: string;
  gender: string;
  classRoom: string;
  classRoomName: string;
  father: {
    firstName: string;
    lastName: string;
    phone: string;
  };
  mother: {
    firstName: string;
    lastName: string;
    phone: string;
  };
  photo: string;
  adress: string;
  classrooms: string[];
}

interface ClassRoom {
  _id: string;
  name: string;
}

export default defineComponent({
  name: "Student-list",
  components: {},
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const apiUrl = store.getters.serverConfigUrl.base_url + "/";

    const sy = window.localStorage.getItem("activeSchoolarYear");

    const studentsData = ref<Array<IStudents>>([]);
    const tableData = ref<Array<IStudents>>([]);
    const classRooms = ref<ClassRoom[]>([]);
    const loading = ref<boolean>(true);

    // eslint-disable-next-line
    const selectedData = ref<any>({
      father: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      mother: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      adress: "",
    });
    const selectStudent = function (data) {
      let selected = {
        father: data.father,
        mother: data.mother,
        adress: data.adress,
      };
      selectedData.value = selected;
    };

    onMounted(async () => {
      const jwtTrimester = JwtService.getTrimester();
      let trimester = "1";
      if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;
      // get teacher's classrooms
      const classrooms: string[] = [];
      await ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
          trimester: trimester,
        },
      })
        .then(({ data }) => {
          for (const classroom of data) {
            classRooms.value.push({
              _id: classroom.classRoom._id,
              name: classroom.classRoom.name,
            });
            classrooms.push(classroom.classRoom._id);
          }
        })
        .catch((e) => console.log(e));

      const match = {};
      match[`schoolarYearsHistory.${sy}`] = { $in: classrooms };

      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ["$classRoom._id", 0],
              },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              father: {
                $arrayElemAt: ["$parent.father", 0],
              },
              mother: {
                $arrayElemAt: ["$parent.mother", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              adress: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentsData.value = data;
          tableData.value = data;
        })
        .catch((e) => console.log(e))
        .finally(() => (loading.value = false));

      setCurrentPageBreadcrumbs(t("student.myStudents"), []);
    });

    const search = ref<string>("");
    const selectedClass = ref<string>("");

    const searchItems = () => {
      studentsData.value = tableData.value.filter((student) => {
        const toSearch = search.value.toLowerCase();
        return (
          (student.firstName.toLowerCase().includes(toSearch) ||
            student.lastName.toLowerCase().includes(toSearch)) &&
          (selectedClass.value == "" ||
            student.classRoom == selectedClass.value)
        );
      });
    };

    return {
      t,
      moment,
      selectStudent,
      selectedData,
      studentsData,
      tableData,
      search,
      searchItems,
      apiUrl,
      selectedClass,
      classRooms,
      loading,
    };
  },
});
